export default class PestAlerts {
    private recaptchaPassed: boolean;

    constructor() {
        this.recaptchaPassed = false;
        this.init();
    }

    private init(): void {
        const form = this.getFormElement();
        const emailInput = this.getEmailInput();
        let selectElements = this.getActiveSelectElements();
        const signUpBtn = this.getSignUpButton();
        let selectArray = Array.from(selectElements);
        const tabs = this.getTabs();

        this.setupTabChangeListener(tabs, signUpBtn, selectArray);
        this.preventFormSubmissionOnEnter(form);
        this.initializeRecaptcha();
        this.setupInputListeners(emailInput, selectArray, signUpBtn);
        this.setupSignUpButtonListener(signUpBtn, emailInput);
        this.validateForm(emailInput, selectArray, signUpBtn);
    }

    // Get the form element.
    private getFormElement(): HTMLFormElement {
        return document.querySelector('.PestForm') as HTMLFormElement;
    }

    // Get the Email element.
    private getEmailInput(): HTMLInputElement {
        return document.getElementById('email') as HTMLInputElement;
    }

    // Get the dropdown for the current open tab.
    private getActiveSelectElements(): NodeListOf<HTMLSelectElement> {
        return document.querySelectorAll('.tab__content .tab__pane.active select') as NodeListOf<HTMLSelectElement>;
    }

    // Get the signup button.
    private getSignUpButton(): HTMLButtonElement {
        return document.querySelector('.sign-up-btn') as HTMLButtonElement;
    }

    // Get the tab buttons.
    private getTabs(): NodeListOf<HTMLAnchorElement> {
        return document.querySelectorAll('.tab__nav__item__link') as NodeListOf<HTMLAnchorElement>;
    }

    // Tab Click listener.
    private setupTabChangeListener(tabs: NodeListOf<HTMLAnchorElement>, signUpBtn: HTMLButtonElement, selectArray: HTMLSelectElement[]): void {
        tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                this.onTabClick(signUpBtn, selectArray);
            });
        });
    }

    /**
     * On Tab Click:
     * 1- Disable submit button
     * 2- Reset the selected option.
     * 3- Update the selector to point to the newly active tab's dropdown.
     * 4- Bind the 'change' listener to the newly active dropdown.
     *
     * */
    private onTabClick(signUpBtn: HTMLButtonElement, selectArray: HTMLSelectElement[]): void {
        signUpBtn.classList.add('disabled');
        signUpBtn.setAttribute('href', '#');
        setTimeout(() => {
            this.resetSelectElements();
            selectArray = this.refreshSelectElements();
            this.bindSelectElementEvents(selectArray);
        }, 250);
    }

    // Reset the selected option.
    private resetSelectElements(): void {
        const selectElements = this.getActiveSelectElements();
        selectElements.forEach(selectElement => {
            selectElement.selectedIndex = 0;
        });
    }

    // Update the selector to point to the newly active tab's dropdown.
    private refreshSelectElements(): HTMLSelectElement[] {
        const selectElements = this.getActiveSelectElements();
        return Array.from(selectElements);
    }

    // Bind the 'change' listener to the newly active dropdown to validate the form in the newly active tab.
    private bindSelectElementEvents(selectArray: HTMLSelectElement[]): void {
        const emailInput = this.getEmailInput();
        const signUpBtn = this.getSignUpButton();
        selectArray.forEach(selectElement => {
            selectElement.addEventListener('change', () => {
                this.validateForm(emailInput, selectArray, signUpBtn);
            });
        });
    }

    // Prevent the user from submitting the form using the 'enter' key.
    private preventFormSubmissionOnEnter(form: HTMLFormElement): void {
        form.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        });
    }

    // Call validation function upon changing any of the input fields.
    private setupInputListeners(emailInput: HTMLInputElement, selectArray: HTMLSelectElement[], signUpBtn: HTMLButtonElement): void {
        emailInput.addEventListener('input', () => {
            this.validateForm(emailInput, selectArray, signUpBtn);
        });

        selectArray.forEach(selectElement => {
            selectElement.addEventListener('change', () => {
                this.validateForm(emailInput, selectArray, signUpBtn);
            });
        });
    }

    // Call the submit form function upon clicking submit IF recaptcha is passed.
    private setupSignUpButtonListener(signUpBtn: HTMLButtonElement, emailInput: HTMLInputElement): void {
        signUpBtn.addEventListener('click', () => {
            if (this.recaptchaPassed && !signUpBtn.classList.contains('disabled')) {
                this.submitForm(emailInput);
            } else {
                console.error('Please complete the reCAPTCHA challenge.');
            }
        });
    }

    // Validate the form and remove the disabled attribute if all data is filled.
    private validateForm(emailInput: HTMLInputElement, selectElements: HTMLSelectElement[], signUpBtn: HTMLButtonElement): void {
        const email = emailInput.value.trim();
        const validEmail = this.isValidEmail(email);
        const countryRegionSelected = this.isCountryRegionSelected(selectElements);

        if (validEmail && countryRegionSelected && this.recaptchaPassed) {
            signUpBtn.classList.remove('disabled');
            signUpBtn.removeAttribute('href');
        } else {
            signUpBtn.classList.add('disabled');
            signUpBtn.setAttribute('href', '#');
        }
    }

    // Check email format validity.
    private isValidEmail(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    // Check if the user has selected a country/region from the dropdown.
    private isCountryRegionSelected(selectElements: HTMLSelectElement[]): boolean {
        return selectElements.some(selectElement => {
            return selectElement.value !== '' && selectElement.value !== 'Country' && selectElement.value !== 'Region';
        });
    }

    // Build the URL using the entered data and redirect to it.
    private submitForm(emailInput: HTMLInputElement): void {
        const activeSelectElements = this.getActiveSelectElements();
        if (activeSelectElements.length === 0) {
            console.error('No active select element found.');
            return;
        }

        const activeDropdown = activeSelectElements[0];
        const queryText = this.getQueryText();
        const email = encodeURIComponent(emailInput.value);
        const selectedOption = activeDropdown.options[activeDropdown.selectedIndex];
        const countryRegionValue = this.getCountryRegionValue(selectedOption);
        const key = this.getDropdownKey(activeDropdown, selectedOption);

        const url = `/action/pestAlert?query=${queryText}&email=${email}${key}${countryRegionValue}`;
        window.location.href = url;
    }

    // Get the query text provided by FE in order to build the URL.
    private getQueryText(): string {
        return (document.querySelector('input[name="queryText"]') as HTMLInputElement).value;
    }

    // Get the value of the selected country/region.
    private getCountryRegionValue(selectedOption: HTMLOptionElement): string {
        return selectedOption.textContent === "Global" ? "" : encodeURIComponent(selectedOption.textContent);
    }

    // Since the user will select region of country, the key passed to FE will be dynamic (either region or country)
    private getDropdownKey(activeDropdown: HTMLSelectElement, selectedOption: HTMLOptionElement): string {
        if (activeDropdown.id === 'regionDropDown' && selectedOption.textContent !== 'Global') {
            return '&region=';
        } else if (activeDropdown.id === 'countryDropDown') {
            return '&country=';
        }
        return '';
    }

    // Initialize ReCaptcha challenge
    private initializeRecaptcha(): void {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window['captcha_filled'] = () => {
            this.onRecaptchaFilled();
        };
        window['captcha_expired'] = () => {
            this.onRecaptchaExpired();
        };
    }

    // On RecaptchaFilled callback call te validate form again to disable the signup button
    private onRecaptchaFilled(): void {
        this.recaptchaPassed = true;
        this.validateForm(this.getEmailInput(), Array.from(this.getActiveSelectElements()), this.getSignUpButton());
    }

    // On onRecaptchaExpired callback call te validate form again to disable the signup button
    private onRecaptchaExpired(): void {
        this.recaptchaPassed = false;
        this.validateForm(this.getEmailInput(), Array.from(this.getActiveSelectElements()), this.getSignUpButton());
    }
}
